import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import './Footer.scss'

import { email_href_mailto_crypted, htmlBrandName } from '../constants'
import { linkTo_UnCryptMailto } from '../utils/unCryptMail'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const Footer = ({
  component: Component = 'div',
  ...other
}) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 160, height: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          social {
            facebook
            instagram
          }
        }
      }
    }
  `)
  const { avatar, site } = data

  return (
    <div className='bg-tint-secondary'>
      <Component className='footer' {...other}>
        <PreviewCompatibleImage className='avatar' alt='Kira Huber' imageInfo={{
          image: avatar,
        }} />
        <p>
          Ich bin Kira – Auftragsmalerin, Künstlerin, Hundenärrin und Schöpferin
                  von {htmlBrandName}.
                </p>
        <p className='strong'>Ich bringe deinen Vierbeiner auf Papier.</p>
        <p>
          Schreibe mir eine Nachricht an&nbsp;
                    <span className='text-nowrap'><i className='far fa-envelope' />&nbsp;
                    <button
              className='link'
              onClick={() => { linkTo_UnCryptMailto(email_href_mailto_crypted) }} >
              hallotiergetreu [at] gmail [punkt] com
                    </button></span>
        </p>
        <div className='links-container'>
          <div className='social-media'>
            <a href={`https://www.facebook.com/${site.siteMetadata.social.facebook}`}><i className='fab fa-facebook' /></a>
            <a href={`https://www.instagram.com/${site.siteMetadata.social.instagram}`}><i className='fab fa-instagram' /></a><br />
          </div>
          <div className='nav-links'>
            <Link to='/impressum'>Impressum</Link> | <Link to='/datenschutzerklaerung'>Datenschutz</Link> | <Link to='/faq'>FAQs</Link>
          </div>
        </div>
      </Component>
    </div>
  )
}

export default Footer
