import React from 'react'
import './CookieConsent.scss'
import { Link } from 'gatsby'
import ReactCookieConsent, { Cookies } from 'react-cookie-consent'

const cookieOptions = {
    cookieName: 'gdpr-analytics-enabled',
    expires: 365,
}

const CookieConsent = () => {
    return (
        <ReactCookieConsent
            cookieName={cookieOptions.cookieName}
            disableStyles={true}
            location='bottom'

            containerClasses='cookie-consent container alert alert-warning'
            contentClasses='cookie-consent--content'
            buttonWrapperClasses='cookie-consent--button-wrapper'
            buttonClasses='btn btn-primary'
            declineButtonClasses='btn btn-light'
            style={{ position: 'fixed', left: 0, right: 0 }}

            enableDeclineButton
            buttonText='Annehmen'
            declineButtonText='Ablehnen'
            
            onAccept={() => {
                // @HACK: `react-cookie-consent` automatically sets the cookie but unfortunately *after*
                // calling `onAccept()`. For the `window.trackGoogleAnalytics()`-call to be effective, we have to make
                // sure that the Cookie is set *beforehand*, which we'll do manually here.
                // Note: This results the cookie to be set twice, once here manually and then again automatically in
                // `react-cookie-consent`'s code.
                const { cookieName, ...extraCookieOptions } = cookieOptions
                Cookies.set(cookieName, true, { ...extraCookieOptions })

                window.trackGoogleAnalytics && window.trackGoogleAnalytics()
            }}
        >
            Diese Website nutzt Cookies.<br />
            Cookies helfen mir den Traffic auf dieser Website auszuwerten. Mehr über den Einsatz von Cookies auf dieser
            Website erfährst du in der <Link to='/datenschutzerklaerung'>Datenschutzerklärung</Link>.
        </ReactCookieConsent>
    )
}

export default CookieConsent