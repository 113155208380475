import React from "react"
import CookieConsent from "./CookieConsent"
import Footer from "./Footer"
import NavigationBar from "./NavigationBar"

import Container from 'react-bootstrap/Container'

const Layout = ({ location, children }) => {
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
        header = (
            <React.Fragment />
        )
    } else {
        header = (
            <React.Fragment />
        )
    }
    return (
        <div style={{ position: 'relative' }}>
            <NavigationBar />
            <header>{header}</header>
            <main role='main'>{children}</main>
            <footer>
                <Footer component={Container} />
            </footer>
            <CookieConsent />
        </div>
    )
}

export default Layout