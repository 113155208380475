import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo, ...otherProps }) => {
    const {
        childImageSharp,
        image
    } = imageInfo

    if (!!image && !!image.childImageSharp) {
        return (
            <Img fixed={image.childImageSharp.fixed} 
                fluid={image.childImageSharp.fluid} {...otherProps} />
        )
    }

    if (!!childImageSharp) {
        return <Img fixed={childImageSharp.fixed}
            fluid={childImageSharp.fluid} {...otherProps} />
    }

    if (!!image && typeof image === 'string')
        // eslint-disable-next-line jsx-a11y/alt-text
        return <img src={image} {...otherProps} />

    if (!!image.publicURL)
        // eslint-disable-next-line jsx-a11y/alt-text
        return <img src={image.publicURL} {...otherProps} />

    return null
}

PreviewCompatibleImage.propTypes = {
    imageInfo: PropTypes.shape({
        alt: PropTypes.string,
        childImageSharp: PropTypes.object,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        style: PropTypes.object,
    }).isRequired,
}

export default PreviewCompatibleImage
