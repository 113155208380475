import React from 'react'

// Crypt/Decrypt at: https://www.math.uni-hamburg.de/it/dienste/encryptma.html
export const email_data_privacy_href_mailto_crypted = 'nbjmup;ibmmpujfshfusfvAhnbjm/dpn'  // hallotiergetreu@gmail.com
export const email_href_mailto_crypted = 'nbjmup;ibmmpujfshfusfvAhnbjm/dpn'  // hallotiergetreu@gmail.com

export const htmlBrandName = (
    <React.Fragment>
        <span style={{ fontWeight: 'bold' }}>TIER</span>
        <span>GETREU</span>
    </React.Fragment>
)
