import React from 'react'
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo from '../assets/logos/logo-header.svg'
import Button from 'react-bootstrap/Button'
import { settingShopComingSoon } from '../config'

import './NavigationBar.scss'
import Container from 'react-bootstrap/Container'

const onSelectDoNothing = () => { }

const Link = ({ ...otherProps }) => {
    return (
        <GatsbyLink activeClassName='active' partiallyActive {...otherProps} />
    )
}

const NavigationBar = () => {
    const data = useStaticQuery(
        graphql`
            query NavigationBarQuery {
                allContentfulPhotoAlbum(
                    sort: { fields: [order], order: ASC }
                ) {
                    photoAlbums: edges {
                        photoAlbum: node {
                            title
                            slug
                        }
                    }
                }
            }
        `
    )
    const { allContentfulPhotoAlbum: { photoAlbums } } = data
    const expand = 'lg'

    return (
        <Navbar expand={expand} sticky='top' collapseOnSelect={true}>
            <Container fluid='lg'>
                <Navbar.Brand as={Link} to='/'><img height={40} src={logo} alt='brand-logo' /></Navbar.Brand>
                <div className={`actions order-${expand}-2`}>
                    <Button as={Link} to='/anfragen' variant='request'>Jetzt anfragen</Button>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                </div>
                <Navbar.Collapse id='basic-navbar-nav'>
                    {/* activeKey='', to disable Nav-Component's behaviour to automatically set 'active'-class
                            on children. Children are inferring their active-style themselves. */}
                    <Nav className='w-100' activeKey='' onSelect={onSelectDoNothing}>
                        <Nav.Link as={Link} to='/' partiallyActive={false}>Home</Nav.Link>
                        <Nav.Link as={Link} to='/anfragen'>Portraitanfrage</Nav.Link>
                        {/* TODO: Remove this code-separation once `settingShopComingSoon` becomes obsolete */}
                        {settingShopComingSoon ? (
                            <Nav.Link as={Link} to='/shop'>Shop</Nav.Link>
                        ) : (
                                <NavDropdown title='Shop' id='basic-nav-dropdown'>
                                    <NavDropdown.Item as={Link} to='/shop' partiallyActive={false}>Übersicht</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to='/shop/tasse'>Individuelle Tasse</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to='/shop/t-shirt'>Individuelles T-Shirt</NavDropdown.Item>
                                </NavDropdown>
                        )}
                        <NavDropdown title='Maltechniken' id='basic-nav-dropdown'>
                            {photoAlbums.map(({ photoAlbum }) => (
                                <NavDropdown.Item key={photoAlbum.slug} as={Link} to={`/${photoAlbum.slug}`}>
                                    {photoAlbum.title}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        <Nav.Link as={Link} to='/about'>Über mich</Nav.Link>

                        <div className="container-actions">
                            {/* TODO: Remove the `hidden`-attribute once `settingShopComingSoon` becomes obsolete */}
                            <Button hidden={settingShopComingSoon} as={Link} to='/shop' variant='request-alt'>
                                Zum Shop
                            </Button>
                            {/* CR: Button has been moved to permanent NavigationBar-portion. */}
                            {/* <Button as={Link} to='/anfragen' variant='request'>Portrait anfragen</Button> */}
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar
